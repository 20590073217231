import { Drawer } from '@mui/material'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import { duplicateTrackingId } from '@returnmates/client-core/src/constants/errors'
import { SourceType } from '@returnmates/client-core/src/constants/sourceType'
import {
  AdminPackage,
  LabelType,
  Media,
  Package,
  PackageStatus,
  Trip,
} from '@returnmates/client-core/src/graphql/generated/api'
import { SnackBarStatuses } from '@returnmates/client-core/src/type'
import cognitoErrorMapper from '@returnmates/client-core/src/utils/errorMapper'
import errorMapper from '@returnmates/client-core/src/utils/errorMapper'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import validate from '@returnmates/client-core/src/utils/validation/package'
import { BackdropProps } from '@returnmates/ui-core/src/constants/drawer'
import { FORM_ERROR, setIn } from 'final-form'
import arrayMutators from 'final-form-arrays'
import moment from 'moment'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'

import EditPackageFormInner from './components/EditPackageFormInner'
import MissingLabelModal from './components/MissingLabelModal'
import MultiPackageModal from './components/MultiPackageModal'
import PackageForm from './components/PackageForm'
import { digitalLabels } from './constants'
import useStyles from './styles'
import { Modals, PackageInConsolidation } from './types'
import { getFilteredAddress } from './utils'

interface Props {
  isOpen: boolean
  currentPackageData: Package | null
  onClose: () => void
  packageConsolidationId?: string | null
  setPackageConsolidationId?: (packageId: string | null) => void
}

function EditPackageForm({
  isOpen,
  currentPackageData,
  onClose,
  packageConsolidationId,
  setPackageConsolidationId,
}: Props) {
  const classes = useStyles({})

  const overlayRef = useRef<HTMLDivElement>(null)
  const currentPackage = useRef<Package | null>(null)
  const currentConsolidationId = useRef<string | null | undefined>(null)

  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [submitError, setSubmitError] = useState<{ [key: string]: string } | null>(null)
  const [initialValues, setInitialValues] = useState<PackageInConsolidation | null>(null)
  const [modalTypes, setModalsTypes] = useState<Modals[]>([])
  const [isMultiPackage, setIsMultiPackage] = useState<boolean>(false)

  const createNewTrip = useCallback(
    async (addressId: string) => {
      try {
        await createAsyncAction(
          dispatch,
          actions.splitPackageByAddressId.request({
            id: currentPackageData?.id,
            input: {
              addressId,
            },
          }),
        )
      } catch (error) {
        dispatch(
          actions.addSnackBar.request({
            type: SnackBarStatuses.ERROR,
            message: error,
          }),
        )
      }
    },
    [dispatch, currentPackageData?.id],
  )

  const updateTrip = useCallback(
    async (addressId: string) => {
      try {
        await createAsyncAction(
          dispatch,
          actions.updateTripPackagesAddressId.request({
            id: currentPackageData?.tripId,
            input: {
              addressId,
            },
          }),
        )
      } catch (error) {
        const { message } = error as Error

        dispatch(
          actions.addSnackBar.request({
            type: SnackBarStatuses.ERROR,
            message: errorMapper(message || (error as string)),
          }),
        )
      }
    },
    [dispatch, currentPackageData?.tripId],
  )

  const updatePackage = useCallback(
    async (
      pack: PackageInConsolidation,
      isStartLoading = true,
      closeAfterUpdate = true,
      snackBarMessage = '',
    ) => {
      if (
        digitalLabels.includes(pack.labelType) &&
        !pack.labelUrl &&
        !modalTypes.includes(Modals.LABEL)
      ) {
        setModalsTypes(prev => [...prev, Modals.LABEL])

        return
      }

      const isAddressIdChanged = currentPackageData?.address?.id !== pack.addressId
      let isMultiPack

      if (isAddressIdChanged && !modalTypes.includes(Modals.MULTI_PACKAGE)) {
        setIsLoading(true)

        try {
          const trip: Trip = await createAsyncAction(
            dispatch,
            actions.getTrip.request({
              id: pack?.tripId,
            }),
          )

          setIsMultiPackage(trip && trip?.packages && trip?.packages.length > 1)
          isMultiPack = trip && trip?.packages && trip?.packages.length > 1
        } catch (error) {
          console.log(error)
        } finally {
          setIsLoading(false)
        }

        if (isAddressIdChanged && isMultiPack && !modalTypes.includes(Modals.MULTI_PACKAGE)) {
          setModalsTypes(prev => [...prev, Modals.MULTI_PACKAGE])

          return
        }
      }

      setModalsTypes([])
      setIsLoading(isStartLoading)

      try {
        if (!moment(pack.packageDate).isSame(currentPackageData?.packageDate)) {
          await createAsyncAction(
            dispatch,
            actions.reschedulePackages.request({
              packageIds: [pack.id],
              date: moment(pack.packageDate).format('YYYY-MM-DD'),
              updateSource: SourceType.WILSON,
            }),
          )
        }

        if (!isMultiPackage && currentPackageData?.tripId && isAddressIdChanged) {
          updateTrip(pack.addressId as string)
        }

        if (
          pack.requiresAgeVerification !== currentPackageData?.requiresAgeVerification &&
          !pack.requiresAgeVerification
        ) {
          await createAsyncAction(
            dispatch,
            actions.clearRequiresAgeVerification.request({ id: pack.id }),
          )
        }

        if (!isMultiPackage) {
          await createAsyncAction(
            dispatch,
            actions.updatePackage.request({
              id: pack.id,
              input: {
                addressId: pack.addressId,
                labelType: pack.labelType as LabelType,
                status: pack.status,
                labelUrl: pack.labelUrl ?? 'https://returnmates.com/null',
                description: pack.description,
                carrierId: pack.carrier?.id,
                retailerId: pack.retailer?.id || null,
                partnerCode: pack.partner?.code,
                trackingId: pack.trackingId,
                condition: pack.condition,
                tripId: pack?.tripId || null,
                requiresBox: pack.requiresBox,
                isSwap: pack.isSwap,
                returnDestination: pack.returnDestination,
                returnDestinationId: pack.returnDestinationId || null,
                serviceType: pack.serviceType,
                date: moment(pack.packageDate).format('yyyy-MM-DD'),
                length: Number(pack.length),
                width: Number(pack.width),
                height: Number(pack.height),
                weight: Number(pack.weight),
                packageDelayReason: pack.packageDelayReason,
                requiresAgeVerification: pack.requiresAgeVerification,
                requiresDeliverySignature: pack.requiresDeliverySignature,
                claim: pack.claim,
                claimReason: pack.claimReason,
                method: pack.method,
              },
            }),
          )
        }
        if (pack.instructions !== currentPackageData?.address?.instructions) {
          await createAsyncAction(
            dispatch,
            actions.updateAddress.request({
              id: pack.addressId,
              input: {
                name: pack.name,
                phone: pack.phone,
                street: pack.street,
                city: pack.city,
                state: pack.state,
                zipCode: pack.zipCode,
                unit: pack.unit,
                instructions: pack.instructions,
              },
            }),
          )
        }

        const hasConsolidationIdChanged = packageConsolidationId !== pack.consolidationId

        const needRemovePackageFromConsolidation =
          packageConsolidationId &&
          (hasConsolidationIdChanged ||
            [PackageStatus.FAILED, PackageStatus.CANCELED].includes(pack.status))

        if (needRemovePackageFromConsolidation) {
          setPackageConsolidationId && setPackageConsolidationId(null)
          currentConsolidationId.current = null

          await createAsyncAction(
            dispatch,
            actions.deleteConsolidationPackage.request({
              packageId: pack.id,
              consolidationId: packageConsolidationId,
            }),
          )
        }

        if (pack.consolidationId && hasConsolidationIdChanged) {
          setPackageConsolidationId?.(pack.consolidationId)
          currentConsolidationId.current = pack.consolidationId

          await createAsyncAction(
            dispatch,
            actions.createConsolidationPackage.request({
              packageId: pack.id,
              consolidationId: pack.consolidationId,
            }),
          )
        }

        const mediaToDelete =
          currentPackageData?.media?.filter(
            mediaItem => !pack.media?.find((mi: Media) => mi.id === mediaItem?.id),
          ) || []

        for (let i = 0; i < mediaToDelete.length; i++) {
          if (mediaToDelete[i]?.id) {
            await createAsyncAction(
              dispatch,
              actions.deleteTripMedia.request({
                id: mediaToDelete[i]?.id,
              }),
            )
          }
        }

        const mediaToCreate = pack.media?.filter(mediaItem => !mediaItem?.id) || []

        for (let i = 0; i < mediaToCreate.length; i++) {
          await createAsyncAction(
            dispatch,
            actions.createTripMedia.request({
              tripId: pack.tripId,
              url: mediaToCreate[i]?.url,
              packageId: pack.id,
              type: 'image/png',
            }),
          )
        }

        if (closeAfterUpdate) {
          onClose()
        }

        if (snackBarMessage) {
          dispatch(
            actions.addSnackBar.request({
              type: SnackBarStatuses.SUCCESS,
              message: snackBarMessage,
            }),
          )
        }
        return true
      } catch (err) {
        const { message } = err as Error

        let errors = {}
        const submitError = { [FORM_ERROR]: cognitoErrorMapper(message || (err as string)) }
        const setError = (key: string, value: string) => {
          errors = setIn(errors, key, value)
        }

        if (submitError[FORM_ERROR] === duplicateTrackingId) {
          setError('trackingId', duplicateTrackingId)
        }

        setSubmitError(errors)

        if (Object.entries(errors).length > 0) {
          return errors
        } else {
          dispatch(
            actions.addSnackBar.request({
              type: SnackBarStatuses.ERROR,
              message: submitError[FORM_ERROR],
            }),
          )
        }
      } finally {
        setIsLoading(false)
      }
    },
    [
      modalTypes,
      currentPackageData,
      dispatch,
      isMultiPackage,
      packageConsolidationId,
      updateTrip,
      setPackageConsolidationId,
      onClose,
    ],
  )

  const handleClick = useCallback(
    e => {
      if (overlayRef.current && overlayRef.current.contains(e.target as Node)) {
        currentPackage.current = null
        currentConsolidationId.current = null
        onClose()
      }
    },
    [onClose, currentPackage],
  )

  const handleReceiveClick = useCallback(
    async (pack: PackageInConsolidation) => {
      await updatePackage({ ...pack, status: PackageStatus.RECEIVED })
    },
    [updatePackage],
  )

  const customValidation = useCallback(
    values => {
      const validation = validate(values)

      return { ...validation, ...submitError }
    },
    [submitError],
  )

  const handleMissingLabelModalClose = useCallback(
    () => setModalsTypes(prev => prev.filter(items => items !== Modals.LABEL)),
    [],
  )

  const handleMultiPackageModalClose = useCallback(
    () => setModalsTypes(prev => prev.filter(items => items !== Modals.MULTI_PACKAGE)),
    [],
  )

  useEffect(() => {
    document.addEventListener('click', handleClick, { capture: true })

    return () => {
      document.removeEventListener('click', handleClick, { capture: true })
    }
  })

  useEffect(() => {
    return () => {
      document.body.style.overflowY = 'unset'
      currentPackage.current = null
      currentConsolidationId.current = null
    }
  }, [isOpen])

  useEffect(() => {
    if (currentPackage.current && isOpen) {
      const currentState = {
        ...currentPackage.current,
        tracking: currentPackageData?.tracking || [],
        consolidationId: currentConsolidationId.current || null,
        ...getFilteredAddress(currentPackage.current?.address || null),
      }

      setInitialValues(currentState)
    } else if (currentPackageData) {
      setInitialValues({
        ...currentPackageData,
        consolidationId: packageConsolidationId || null,
        ...getFilteredAddress(currentPackageData?.address || null),
      })
    }
  }, [currentPackageData, isOpen, packageConsolidationId])

  return (
    <Drawer
      classes={{ paperAnchorRight: classes.drawer }}
      open={isOpen}
      onClose={onClose}
      anchor="right"
      BackdropProps={BackdropProps}
    >
      <Form
        initialValues={
          initialValues ||
          ({
            ...currentPackageData,
            consolidationId: packageConsolidationId,
            ...getFilteredAddress(currentPackageData?.address || null),
          } as PackageInConsolidation)
        }
        onSubmit={updatePackage}
        validate={customValidation}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, values }) => (
          <div className={classes.pickupFormWrapper}>
            <PackageForm
              onClose={onClose}
              tripSection="package"
              idLabel="Package"
              handleSubmit={handleSubmit}
              handleReceiveClick={handleReceiveClick}
              currentPackage={currentPackageData}
              isLoading={isLoading}
            >
              <EditPackageFormInner
                isOpen={isOpen}
                setSubmitError={setSubmitError}
                packageId={currentPackageData?.id}
                package={currentPackageData as Package & AdminPackage}
                consolidationId={packageConsolidationId}
              />
            </PackageForm>
            <MissingLabelModal
              isOpen={modalTypes.includes(Modals.LABEL)}
              onClose={handleMissingLabelModalClose}
              handleSubmit={handleSubmit}
            />
            <MultiPackageModal
              isOpen={modalTypes.includes(Modals.MULTI_PACKAGE)}
              onClose={handleMultiPackageModalClose}
              updateTrip={updateTrip}
              createNewTrip={createNewTrip}
              onSubmit={handleSubmit}
              addressId={values.addressId}
            />
          </div>
        )}
      />
    </Drawer>
  )
}

export default memo(EditPackageForm)
