import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import { AsyncJobDataType } from '@returnmates/client-core/src/graphql/generated/api'
import { SnackBarStatuses } from '@returnmates/client-core/src/type'
import { AsyncJobDataCustom } from '@returnmates/client-core/src/type/admin'
import Close from '@returnmates/ui-core/src/components/images/icons/close'
import { memo, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import useStyles from '../styles'

interface Props {
  asyncJobData?: AsyncJobDataCustom | null
}

function NotificationListItem({ asyncJobData }: Props) {
  const isErrorType = asyncJobData?.type?.toLowerCase().includes('error')
  const classes = useStyles({
    type: isErrorType ? SnackBarStatuses.ERROR : SnackBarStatuses.SUCCESS,
  })
  const dispatch = useDispatch()

  const removeItem = useCallback(() => {
    dispatch(
      actions.removeNotificationItem.request({
        id: asyncJobData?.id,
      }),
    )
  }, [asyncJobData?.id, dispatch])

  const message = useMemo(() => {
    if (asyncJobData?.type === AsyncJobDataType.RESCHEDULE_PACKAGE_ERROR) {
      return JSON.parse(asyncJobData.data).message
    }

    //@ts-ignore
    if (STATUSES.includes(asyncJobData?.type as SnackBarStatuses)) {
      return asyncJobData?.data
    }

    //possible notifcation messages in future

    return 'Something went wrong'
  }, [asyncJobData?.data, asyncJobData?.type])

  return (
    <ListItem
      className={classes.listItem}
      secondaryAction={
        <IconButton edge="end" onClick={removeItem}>
          <Close />
        </IconButton>
      }
    >
      <ListItemText className={classes.listItemText} primary={message} />
    </ListItem>
  )
}

export default memo(NotificationListItem)
