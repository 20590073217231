import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import callApi from '@returnmates/client-core/src/graphql/callApi'
import { AsyncJobDataType, Hub } from '@returnmates/client-core/src/graphql/generated/api'
import {
  jobStatusUpdateSelector,
  notificationsSelector,
} from '@returnmates/client-core/src/selectors/admin'
import { getPartners as getPartnersSelector } from '@returnmates/client-core/src/selectors/admin'
import { getHubs as getHubsSelector } from '@returnmates/client-core/src/selectors/hubs'
import { getUserTokenData } from '@returnmates/client-core/src/selectors/user'
import StandingStore from '@returnmates/client-core/src/standingStore'
import { SnackBarStatuses } from '@returnmates/client-core/src/type'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import useSubscription from '@returnmates/client-core/src/utils/useSubscription'
import SnackBars from '@returnmates/ui-core/src/components/SnackBars'
import Spinner from '@returnmates/ui-core/src/components/Spinner'
import jwt from 'jsonwebtoken'
import {
  createContext,
  lazy,
  memo,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Range } from 'react-date-range'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'

import Header from '../../components/Header'
import PrivateRoute from '../../components/PrivateRoute'
import Sidebar from '../../components/Sidebar'
import {
  AUDITS,
  COMMUNICATIONS,
  CONSOLIDATIONS,
  DASHBOARD,
  LOCATIONS,
  MANIFEST,
  OPERATIONS,
  PACKAGE_LOG,
  PARTNERS,
  PRINT,
  QR,
  TRIPS_LOG,
  USERS,
  ZIP_CODES,
} from '../../constants/paths'
import { todayDateFilter } from '../../constants/staticRanges'
import { cognitoGroups } from '../../constants/userPermissions'
import Consolidations from '../Consolidations'
import { pagesWithServicedPartners } from './constants'
import useStyles from './styles'

const Communications = lazy(() => import('../Communications'))
const Dashboard = lazy(() => import('../Dashboard'))
const Manifest = lazy(() => import('../Manifest'))
const Operation = lazy(() => import('../Operation'))
const Packages = lazy(() => import('../Packages'))
const Locations = lazy(() => import('../Locations'))
const Partners = lazy(() => import('../Partners'))
const Pickups = lazy(() => import('../Pickups'))
const QrReader = lazy(() => import('../QrReader'))
const Users = lazy(() => import('../Users'))
const ZipCodes = lazy(() => import('../ZipCodes'))
const Print = lazy(() => import('../Print'))
const Audits = lazy(() => import('../Audits'))

interface ContextType {
  currentDateRange: Range
  currentHubId: string | null
  setCurrentHubId: (val: string | null) => void
  userRole: string | null
}

interface UserPayload {
  role: string
}

// @ts-ignore
export const Context = createContext<ContextType>(null)

const { admin, manager, operations, warehouse, auditor, saver } = cognitoGroups

const accessRolesA = [admin]
const accessRolesAM = [admin, manager]
const accessRolesAMOW = [admin, manager, operations, warehouse]
const accessRolesAMOWAS = [admin, manager, operations, warehouse, auditor, saver]

function Main() {
  const classes = useStyles()
  const location = useLocation()
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const fromZipCodesRef = useRef(false)
  const fromConsolidationsRef = useRef(false)
  const fromLocationsRef = useRef(false)
  const hubs = useSelector(getHubsSelector)
  const partners = useSelector(getPartnersSelector)
  const userTokenData = useSelector(getUserTokenData)
  const jobStatusUpdate = useSelector(jobStatusUpdateSelector)
  const notifications = useSelector(notificationsSelector)
  const [currentHubId, setCurrentHubId] = useState<string | null>(
    StandingStore.getItem('hubID') as string,
  )
  const [userRole, setUserRole] = useState<string | null>(null)

  const [arrayOfDateRanges, setArrayOfDateRanges] = useState<Array<Range>>(todayDateFilter)

  const [isLoading, setIsLoading] = useState(Boolean(!currentHubId))
  const [contextStore, setContextStore] = useState<ContextType>()

  const currentDateRange = useMemo<Range>(() => arrayOfDateRanges[0], [arrayOfDateRanges])

  useSubscription(
    callApi.onAsyncJobStatusUpdate,
    actions.asyncJobStatusUpdate,
    'onAsyncJobStatusUpdate',
  )

  const getHubs = useCallback(async () => {
    setIsLoading(true)
    try {
      const hubs: Array<Hub> = await createAsyncAction(dispatch, actions.getHubs.request())

      if (hubs.length === 1) {
        setCurrentHubId(hubs[0].id as string)
      }
    } finally {
      setIsLoading(false)
    }
  }, [dispatch])

  const getPartners = useCallback(async () => {
    setIsLoading(true)
    try {
      await createAsyncAction(dispatch, actions.getPartners.request({}))
    } finally {
      setIsLoading(false)
    }
  }, [dispatch])

  const updateDateRange = useCallback((newRange: Range[]) => {
    setArrayOfDateRanges(newRange)
  }, [])

  const handleJobStatusUpdate = useCallback(() => {
    if (!jobStatusUpdate || !Array.isArray(jobStatusUpdate.asyncJobData)) {
      return
    }
    if (jobStatusUpdate.asyncJobData.length >= 5) {
      const notificationMessage = `You got ${jobStatusUpdate.asyncJobData.length} new notifications. Please check them in the notification list`
      dispatch(
        actions.addSnackBar.request({ type: SnackBarStatuses.ERROR, message: notificationMessage }),
      )

      return
    }
    jobStatusUpdate.asyncJobData.forEach(jobStatus => {
      if (
        !jobStatus.type ||
        jobStatus.type === AsyncJobDataType.ON_WORKER_ROUTE_PRICING_CALCULATION ||
        jobStatus.type === AsyncJobDataType.ON_WORKER_ROUTE_PRICING_CALCULATION_INITIAL
      ) {
        return
      }
      const data = JSON.parse(jobStatus?.data || '{}')
      const snackBarMessage = data.message || 'Something went wrong'
      dispatch(
        actions.addSnackBar.request({ type: SnackBarStatuses.ERROR, message: snackBarMessage }),
      )
    })
  }, [jobStatusUpdate, dispatch])

  useEffect(() => {
    const token = userTokenData?.signInUserSession.idToken.jwtToken

    const decodeJwt = jwt.decode(token as string) as UserPayload

    if (decodeJwt) {
      dispatch(actions.setUserRole.request(decodeJwt.role))
    }

    setUserRole(decodeJwt?.role)

    if (!hubs) {
      getHubs()
    } else if (!currentHubId) {
      setIsLoading(false)
    }
  }, [currentHubId, hubs, userRole, userTokenData, dispatch, getHubs])

  useEffect(() => {
    if (fromZipCodesRef.current) {
      dispatch(actions.resetServicedZipCodes.request())
      fromZipCodesRef.current = false
    } else if (fromConsolidationsRef.current) {
      dispatch(actions.resetConsolidations.request())
      fromConsolidationsRef.current = false
    } else if (fromLocationsRef.current) {
      dispatch(actions.resetLocations.request())
      fromLocationsRef.current = false
    }

    if (pagesWithServicedPartners.includes(location.pathname) && !partners.length) {
      getPartners()
    }

    if (location.pathname === ZIP_CODES) {
      fromZipCodesRef.current = true
    } else if (location.pathname === CONSOLIDATIONS) {
      fromConsolidationsRef.current = true
    } else if (location.pathname === LOCATIONS) {
      fromLocationsRef.current = true
    }
  }, [
    currentHubId,
    dispatch,
    getPartners,
    hubs,
    location.pathname,
    partners.length,
    userRole,
    userTokenData,
  ])

  useEffect(() => {
    setContextStore({
      currentDateRange,
      currentHubId: currentHubId,
      setCurrentHubId,
      userRole,
    })
  }, [currentDateRange, currentHubId, userRole])

  useEffect(() => {
    handleJobStatusUpdate()
  }, [dispatch, jobStatusUpdate])

  if (isLoading || !contextStore || !userRole) {
    return (
      <div className={classes.spinnerHandler}>
        <Spinner />
      </div>
    )
  }

  return (
    <div ref={ref} className={classes.root}>
      <Header
        currentHubId={currentHubId}
        setCurrentHubId={setCurrentHubId}
        setCurrentDateRange={updateDateRange}
        currentDateRange={arrayOfDateRanges}
        notifications={notifications}
      />
      <SnackBars />
      <Sidebar setCurrentDateRange={updateDateRange} userRole={userRole} />
      <Context.Provider value={contextStore as ContextType}>
        <div className={classes.main}>
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route
                path={`${PACKAGE_LOG}/*`}
                element={
                  <PrivateRoute userRole={userRole} accessRoles={accessRolesAMOW}>
                    <Packages />
                  </PrivateRoute>
                }
              />
              <Route
                path={`${LOCATIONS}/*`}
                element={
                  <PrivateRoute userRole={userRole} accessRoles={accessRolesAMOW}>
                    <Locations />
                  </PrivateRoute>
                }
              />
              <Route
                path={`${TRIPS_LOG}/*`}
                element={
                  <PrivateRoute userRole={userRole} accessRoles={accessRolesAMOW}>
                    <Pickups />
                  </PrivateRoute>
                }
              />
              <Route
                path={`${CONSOLIDATIONS}/*`}
                element={
                  <PrivateRoute userRole={userRole} accessRoles={accessRolesAMOW}>
                    <Consolidations />
                  </PrivateRoute>
                }
              />
              <Route
                path={DASHBOARD}
                element={
                  <PrivateRoute userRole={userRole} accessRoles={accessRolesAMOW}>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path={`${USERS}/*`}
                element={
                  <PrivateRoute userRole={userRole} accessRoles={accessRolesAMOW}>
                    <Users />
                  </PrivateRoute>
                }
              />
              <Route
                path={QR}
                element={
                  <PrivateRoute userRole={userRole} accessRoles={accessRolesAMOW}>
                    <QrReader />
                  </PrivateRoute>
                }
              />
              <Route
                path={`${MANIFEST}/*`}
                element={
                  <PrivateRoute userRole={userRole} accessRoles={accessRolesAM}>
                    <Manifest />
                  </PrivateRoute>
                }
              />
              <Route
                path={`${OPERATIONS}/*`}
                element={
                  <PrivateRoute userRole={userRole} accessRoles={accessRolesAM}>
                    <Operation />
                  </PrivateRoute>
                }
              />
              <Route
                path={ZIP_CODES}
                element={
                  <PrivateRoute userRole={userRole} accessRoles={accessRolesA}>
                    <ZipCodes />
                  </PrivateRoute>
                }
              />
              <Route
                path={PRINT}
                element={
                  <PrivateRoute userRole={userRole} accessRoles={accessRolesAMOW}>
                    <Print />
                  </PrivateRoute>
                }
              />
              <Route
                path={COMMUNICATIONS}
                element={
                  <PrivateRoute userRole={userRole} accessRoles={accessRolesAMOW}>
                    <Communications />
                  </PrivateRoute>
                }
              />
              <Route
                path={PARTNERS}
                element={
                  <PrivateRoute userRole={userRole} accessRoles={accessRolesA}>
                    <Partners />
                  </PrivateRoute>
                }
              />
              <Route
                path={AUDITS}
                element={
                  <PrivateRoute userRole={userRole} accessRoles={accessRolesAMOWAS}>
                    <Audits />
                  </PrivateRoute>
                }
              />
              <Route
                path="*"
                element={
                  <PrivateRoute userRole={userRole} accessRoles={accessRolesAMOW}>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
            </Routes>
          </Suspense>
        </div>
      </Context.Provider>
    </div>
  )
}

export default memo(Main)
