import { LABEL_TYPE_MAP, PACKAGE_STATUS_MAP } from '@returnmates/client-core/src/constants/trip'
import {
  LabelType,
  PackageStatus,
  TripType,
} from '@returnmates/client-core/src/graphql/generated/api'

import { ColumnTypes, FilterPanelType } from '../GridTable/types'

export const packageColumns = [
  {
    name: 'status',
    title: 'Status',
    type: ColumnTypes.TEXT,
    filterable: true,
    filterPanelType: FilterPanelType.RADIO_GROUP,
    filterRadioOptions: PACKAGE_STATUS_MAP,
    searchable: false,
    sortingEnabled: true,
    width: 200,
  },
  {
    name: 'packageDate',
    title: 'Package Date',
    type: ColumnTypes.TEXT,
    filterable: false,
    sortingEnabled: true,
    width: 'auto',
  },
  {
    name: 'expectedDate',
    title: 'Expected Date',
    type: ColumnTypes.TEXT,
    filterable: false,
    sortingEnabled: true,
    width: 'auto',
  },
  {
    name: 'labelType',
    title: 'Label',
    type: ColumnTypes.LINK,
    filterable: true,
    filterPanelType: FilterPanelType.RADIO_GROUP,
    filterRadioOptions: LABEL_TYPE_MAP,
    sortingEnabled: false,
    width: 'auto',
  },
  {
    name: 'tripName',
    title: 'Name',
    type: ColumnTypes.TEXT,
    filterable: false,
    sortingEnabled: true,
    width: 'auto',
  },
  {
    name: 'description',
    title: 'Description',
    type: ColumnTypes.TEXT,
    filterable: true,
    sortingEnabled: false,
    width: 'auto',
  },
  {
    name: 'partnerCode',
    title: 'Partner',
    type: ColumnTypes.TEXT,
    sortingEnabled: true,
    width: 'auto',
  },
  {
    name: 'retailer',
    title: 'Retailer',
    type: ColumnTypes.TEXT,
    sortingEnabled: true,
    width: 'auto',
  },
  {
    name: 'carrier',
    title: 'Carrier',
    type: ColumnTypes.TEXT,
    sortingEnabled: false,
    width: 'auto',
  },
  {
    name: 'driver',
    title: 'Driver',
    type: ColumnTypes.TEXT,
    sortingEnabled: false,
    width: 'auto',
  },
  {
    name: 'locationName',
    title: 'Location',
    type: ColumnTypes.TEXT,
    sortingEnabled: true,
    width: 'auto',
  },
  {
    name: 'trackingId',
    title: 'Tracking ID',
    type: ColumnTypes.TEXT,
    searchable: true,
    sortingEnabled: true,
    align: 'center',
    width: 200,
  },
  {
    name: 'actionButton',
    title: ' ',
    filterable: false,
    searchable: false,
    sortingEnabled: false,
    align: 'center',
    width: 100,
  },
]

const polybagIdColumn = {
  name: 'polybagId',
  title: 'Polybag Id',
  type: ColumnTypes.TEXT,
  filterable: false,
  sortingEnabled: true,
  width: 'auto',
}
const locationColumnIndex = packageColumns.findIndex(column => column.name === 'locationName')

export const pickupsPackageColumns = [
  ...packageColumns.slice(0, locationColumnIndex + 1),
  polybagIdColumn,
  ...packageColumns.slice(locationColumnIndex + 1),
]

export const defaultPackageColumnWidths = [...packageColumns, ...pickupsPackageColumns].map(
  col => ({
    columnName: col.name,
    width: col.width,
  }),
)

export const tableColumnExtensions = [
  { columnName: 'status', width: 200 },
  { columnName: 'trackingId', width: 200 },
  { columnName: 'actionButton', width: 100 },
]

export const formattedColumnNames = ['status', 'labelType', 'condition', 'carrier', 'actionButton']

export const disabledStatuses = [PackageStatus.DEPARTED]

export const checkboxFilter = {
  type: 'status',
  values: [
    { key: PackageStatus.PENDING, label: 'Pending' },
    { key: PackageStatus.CANCELED, label: 'Canceled' },
    { key: PackageStatus.CONSOLIDATED, label: 'Consolidated' },
    { key: PackageStatus.FAILED, label: 'Failed' },
    { key: PackageStatus.RTS, label: 'RTS' },
    { key: PackageStatus.DRIVER_LAUNCHED, label: 'Driver Launched' },
    { key: PackageStatus.DRIVER_ARRIVING, label: 'Driver Arriving' },
    { key: PackageStatus.PICKED_UP, label: 'Picked Up' },
    { key: PackageStatus.RECEIVED, label: 'Received' },
    { key: PackageStatus.DEPARTED, label: 'Departed' },
    { key: PackageStatus.DELIVERED, label: 'Delivered' },
    { key: PackageStatus.DELAYED, label: 'Delayed' },
    { key: PackageStatus.IN_CUSTODY, label: 'In Custody' },
    { key: PackageStatus.IN_TRANSIT, label: 'In Transit' },
  ],
}

export const labelTypeFilter = {
  type: 'labelType',
  values: [
    { key: LabelType.PHYSICAL, label: 'Physical' },
    { key: LabelType.DIGITAL, label: 'Digital' },
    { key: LabelType.AMAZON, label: 'Amazon' },
    { key: LabelType.CONSOLIDATED, label: 'Consolidated' },
  ],
}

export const tripTypeFilter = {
  type: 'tripType',
  values: [
    { key: TripType.PICKUP, label: 'Pickup' },
    { key: TripType.DROPOFF, label: 'Delivery' },
  ],
}

export const consolidationsValue = {
  key: 'consolidations',
  label: 'Hide Consolidations',
}

export const unsortedValue = {
  key: 'unsorted',
  label: 'Hide Unsorted',
}

export const printLabels = [
  { key: 'Package ID Labels', label: 'Package ID Labels', disabled: false },
  { key: 'Package Tracking ID', label: 'Package Tracking ID', disabled: false },
  { key: 'Carrier Labels', label: 'Carrier Labels', disabled: true },
]

export const sortDescByName = ['packageDate', 'expectedDate']

export const pickupSearchFields = [
  { key: 'addressName', label: 'Name' },
  { key: 'trackingId', label: 'Tracking ID' },
  { key: 'packageId', label: 'Package ID' },
  { key: 'polybagId', label: 'Polybag ID' },
  { key: 'addressStreet', label: 'Street' },
  { key: 'tripId', label: 'ID' },
  { key: 'addressPhone', label: 'Phone' },
  { key: 'userEmail', label: 'Email' },
]

export const dropoffSearchFields = pickupSearchFields.filter(sf => sf.key !== 'polybagId')

export const MIN_POLYBAG_COUNT = 1
export const MAX_POLYBAG_COUNT = 20

export const NORDSTROM_PARTNER = 'nordstrom'

export const NORDSTROM_ORDER_NUMBER_LABEL_PART =
  '^FS^CF0,40^FO20,550^FDORDER NUMBER:{Package[0].partnerAttributes.order_number}^FS^FO20,610^BY2,2,120^BC,,N^FD{Package[0].partnerAttributes.order_number}'

export const NORDSTROM_RMA_ID_LABEL_PART =
  '^FS^CF0,40^FO20,750^FDRMA ID: {Package[0].partnerAttributes.rma_id}^FS^FO20,810^BY2,2,120^BC,,N^FD{Package[0].partnerAttributes.rma_id}'

export const NORDSTROM_RMA_ID_LABEL_PART_WITHOUT_ORDER_NUMBER =
  '^FS^CF0,40^FO20,550^FDRMA ID: {Package[0].partnerAttributes.rma_id}^FS^FO20,610^BY2,2,120^BC,,N^FD{Package[0].partnerAttributes.rma_id}'

export const NORDSTROM_ITEMS_LABEL_PART =
  '^FS^CF0,60^FO20,970^FDITEMS^FS^CF0,40^FB887,2,15,L^FO20,1040^FD{Package[0].description} {Package[0].partnerAttributes.size} {Package[0].partnerAttributes.color}^FS^CF0,40^FB887,2,15,L^FO20,1160^FD{Package[1].description} {Package[1].partnerAttributes.size} {Package[1].partnerAttributes.color}^FS^CF0,40^FB887,2,15,L^FO20,1280^FD{Package[2].description} {Package[2].partnerAttributes.size} {Package[2].partnerAttributes.color}^FS^CF0,40^FB887,2,15,L^FO20,1400^FD{Package[3].description} {Package[3].partnerAttributes.size} {Package[3].partnerAttributes.color}^FS^CF0,40^FB887,2,15,L^FO20,1520^FD{Package[4].description} {Package[4].partnerAttributes.size} {Package[4].partnerAttributes.color}'

export const NORDSTROM_ITEMS_LABEL_PART_WITHOUT_RMA_ID_OR_ORDER_NUMBER =
  '^FS^CF0,60^FO20,770^FDITEMS^FS^CF0,40^FB887,2,15,L^FO20,840^FD{Package[0].description} {Package[0].partnerAttributes.size} {Package[0].partnerAttributes.color}^FS^CF0,40^FB887,2,15,L^FO20,960^FD{Package[1].description} {Package[1].partnerAttributes.size} {Package[1].partnerAttributes.color}^FS^CF0,40^FB887,2,15,L^FO20,1080^FD{Package[2].description} {Package[2].partnerAttributes.size} {Package[2].partnerAttributes.color}^FS^CF0,40^FB887,2,15,L^FO20,1200^FD{Package[3].description} {Package[3].partnerAttributes.size} {Package[3].partnerAttributes.color}^FS^CF0,40^FB887,2,15,L^FO20,1320^FD{Package[4].description} {Package[4].partnerAttributes.size} {Package[4].partnerAttributes.color}'
