import { Checkbox } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import { PURE_RED } from '@returnmates/client-core/src/constants/colors'
import { SourceType } from '@returnmates/client-core/src/constants/sourceType'
import {
  LABEL_TYPE_MAP,
  PACKAGE_CONDITION,
  PACKAGE_DELAY_REASON_MAP,
  TRIP_METHOD_MAP,
} from '@returnmates/client-core/src/constants/trip'
import {
  Address,
  Carrier,
  ClaimReasons,
  LabelType,
  Package,
  PackageStatus,
  Partner,
  PartnerDestination,
  Retailer,
  TripType,
  User,
} from '@returnmates/client-core/src/graphql/generated/api'
import { getPackageServiceTypes } from '@returnmates/client-core/src/selectors/admin'
import {
  getBarcodeScan,
  getCarriers,
  getConsolidations as getConsolidationsSelector,
  getMainRole,
  getRetailers,
} from '@returnmates/client-core/src/selectors/admin'
import {
  getCurrentPackage,
  getPackageTrackingListSelector,
} from '@returnmates/client-core/src/selectors/packages'
import { SnackBarStatuses } from '@returnmates/client-core/src/type'
import { PickupDetails as PickupDetailsType } from '@returnmates/client-core/src/type/pickups'
import errorMapper from '@returnmates/client-core/src/utils/errorMapper'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import AutocompleteInput from '@returnmates/ui-core/src/components/AutocompleteInput'
import Button from '@returnmates/ui-core/src/components/Button'
import Input from '@returnmates/ui-core/src/components/Input'
import useOutsideClick from '@returnmates/ui-core/src/hooks/useOutsideClick'
import clsx from 'clsx'
import moment from 'moment'
import { HTMLAttributes, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Field, useField } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useDispatch, useSelector } from 'react-redux'

import Select from '../../../components/Select'
import { cognitoGroups } from '../../../constants/userPermissions'
import useEnabledPartners from '../../../hooks/useEnabledPartners'
import NewAddressDropdown from '../../../pages/Consolidations/components/NewAddressDropdown'
import PaperComponentCustom from '../../../pages/Consolidations/components/PaperComponentCustom'
import {
  canLinkPackageToConsolidationStatuses,
  notRemovePackageFromConsolidationStatuses,
  notUpdatePackageReturnDestinationIdStatuses,
} from '../../../pages/Packages/constants'
import EditAddressFormInner from '../../EditAddressForm/EditAddressFormInner'
import CalendarIcon from '../../images/icons/calendar'
import CheckedSelectorIcon from '../../images/icons/checkedSelector'
import CloseIcon from '../../images/icons/close'
import EraserIcon from '../../images/icons/eraser'
import PlusIcon from '../../images/icons/plus'
import SelectorIcon from '../../images/icons/selector'
import MediaData from '../../MediaData'
import RemovePackageModal from '../../RemovePackageModal'
import SingleDatePicker from '../../SingleDatePicker'
import UploadLabelField from '../../UploadLabelField'
import WrappedSelect from '../../WrappedSelect'
import { claimReasons, digitalLabels } from '../constants'
import useStyles from '../styles'
import LocationScansRow from './LocationScansRow'
import PackageStatusSelect from './PackageStatusSelect'
import ScansRow from './ScansRow'
import TrackingRow from './TrackingRow'

interface Props {
  isOpen: boolean
  setSubmitError: (val: { [key: string]: string } | null) => void
  packageId?: string
  package: Package
  consolidationId?: string | null | undefined
}

const emptyArray: Carrier[] = []

function EditPackageFormInner({
  isOpen,
  setSubmitError,
  packageId,
  package: startPackage,
  consolidationId: startConsolidationId,
}: Props) {
  const classes = useStyles()
  const { input: labelUrl } = useField('labelUrl')
  const { input: labelType } = useField('labelType')
  const { input: inputMedia } = useField('media')
  const { input: retailer } = useField('retailer')
  const { input: carrierInput } = useField('carrier')
  const { input: trackingId } = useField('trackingId')
  const { input: consolidationId } = useField('consolidationId')
  const { input: scans } = useField('scans')
  const { input: locationScans } = useField('locationScans')
  const { input: partner } = useField<Partner>('partner')
  const { input: partnerDestinationIdInput } = useField('returnDestinationId')
  const { input: status } = useField('status')
  const { input: requiresAgeVerification } = useField<number | null>('requiresAgeVerification')
  const { input: requiresDeliverySignature } = useField<boolean>('requiresDeliverySignature')
  const { input: claim } = useField('claim')
  const { input: claimReason } = useField('claimReason')
  const { input: consolidatedBy } = useField('consolidatedBy')

  const [isRemovePackageModalOpen, setIsRemovePackageModalOpen] = useState(false)
  const [retailerInput, setRetailerInput] = useState('')
  const [retailerError, setRetailerError] = useState('')
  const [isRetailerModalOpen, setIsRetailerModalOpen] = useState(false)
  const [isDigitalLabel, setIsDigitalLabel] = useState(false)
  const [isLabelUploaded, setIsLabelUploaded] = useState(false)
  const [isAddNewRetailerDisabled, setIsAddNewRetailerDisabled] = useState(false)
  const [
    partnerDestinations,
    setPartnerDestinations,
  ] = useState<Array<PartnerDestination | null> | null>([])
  const [claimReasonValue, setСlaimReasonValue] = useState<{
    key: ClaimReasons
    label: string
  } | null>(null)
  const [isClaimReasonOpen, setIsClaimReasonOpen] = useState(false)
  const [partnerDestinationValue, setPartnerDestinationValue] = useState<PartnerDestination | null>(
    null,
  )
  const [userAddresses, setUserAddresses] = useState<Address[]>([])
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false)
  const [isDestinationOpen, setIsDestinationOpen] = useState(false)
  const [error, setError] = useState<string>()
  const dispatch = useDispatch()
  const carriers = useSelector(getCarriers)
  const retailers = useSelector(getRetailers)
  const role = useSelector(getMainRole)
  const partners = useEnabledPartners()
  const consolidations = useSelector(getConsolidationsSelector)
  const barcodeScan = useSelector(getBarcodeScan)
  const packageServiceTypes = useSelector(getPackageServiceTypes)
  const packageTrackingList = useSelector(getPackageTrackingListSelector)
  const retailerModalRef = useRef<HTMLDivElement>(null)
  const [isCarrierManuallySelected, setIsCarrierManuallySelected] = useState(false)
  const [consolidatedByUserEmail, setConsolidatedByUserEmail] = useState<string | null>(null)
  const currentPackageData = useSelector(getCurrentPackage)

  const isDeliveriesTripType = useMemo(() => {
    return currentPackageData?.tripType === TripType.DROPOFF
  }, [currentPackageData?.tripType])

  const isPackageDateDisabled = useMemo(() => {
    return startPackage.status === PackageStatus.DELIVERED || !currentPackageData?.tripId
  }, [currentPackageData?.tripId, startPackage.status])

  const isShowPackageDelayReasonSelect = useMemo(
    () => status.value === PackageStatus.DELAYED || currentPackageData?.packageDelayReason,
    [status.value, currentPackageData?.packageDelayReason],
  )

  const handleCloseRemovePackageModal = useCallback(() => {
    setIsRemovePackageModalOpen(false)
  }, [])

  const autoDetectCarrier = useCallback(
    value => {
      const detectedCarrier = carriers?.find(carrier => {
        if (carrier.regex) {
          const regex = new RegExp(carrier.regex)
          return regex.test(value.replace(/ /g, ''))
        }
      })

      carrierInput.onChange(detectedCarrier)
    },
    [carrierInput, carriers],
  )

  const onTrackingIdChange = useCallback(
    val => {
      trackingId.onChange(val)
      if (!isCarrierManuallySelected) {
        autoDetectCarrier(val)
      }

      setSubmitError(null)
    },
    [autoDetectCarrier, isCarrierManuallySelected, setSubmitError, trackingId],
  )

  const getConsolidations = useCallback(async () => {
    try {
      await createAsyncAction(
        dispatch,
        actions.getConsolidations.request({
          where: {
            consolidationId: consolidationId.value,
          },
        }),
      )
    } catch (err) {
      const { message } = err as Error
      dispatch(
        actions.addSnackBar.request({
          type: SnackBarStatuses.ERROR,
          message: errorMapper(message || (err as string)),
        }),
      )
    }
  }, [consolidationId.value, dispatch])

  const packageConsolidation = useMemo(() => {
    return consolidations?.[0]
  }, [consolidations])

  const addRetailer = useCallback(async () => {
    try {
      const newRetailer = await createAsyncAction(
        dispatch,
        actions.createRetailer.request({ name: retailerInput }),
      )

      retailer.onChange(newRetailer)

      setRetailerInput('')
      setIsRetailerModalOpen(false)
    } catch (err) {
      const { message } = err as Error
      setRetailerError(message || (err as string))
    }
  }, [dispatch, retailer, retailerInput])

  const onRetailerChange = useCallback(
    val => {
      const isRetailerAlreadyExist = retailers?.some(
        retailer => retailer.name?.toLocaleLowerCase() === val.toLocaleLowerCase(),
      )

      if (isRetailerAlreadyExist) {
        setIsAddNewRetailerDisabled(true)
        setRetailerError(`${val} already exist`)
      } else {
        setIsAddNewRetailerDisabled(false)
        setRetailerError('')
      }

      setRetailerInput(val)
    },
    [retailers],
  )

  const onClaimReasonSelect = useCallback(
    (_, value) => {
      if (value) {
        claimReason.onChange(value?.key)
        setСlaimReasonValue(value)
        setIsClaimReasonOpen(false)
      } else {
        claimReason.onChange(null)
        setСlaimReasonValue(null)
      }
    },
    [claimReason],
  )

  const onPartnerDestinationSelect = useCallback(
    (_, value) => {
      if (value) {
        partnerDestinationIdInput.onChange(value?.address?.id)
        setPartnerDestinationValue(value)
        setIsDestinationOpen(false)
      } else {
        partnerDestinationIdInput.onChange('')
        setPartnerDestinationValue(null)
      }
    },
    [partnerDestinationIdInput],
  )

  const serviceTypeCondition = useMemo(() => {
    return packageServiceTypes?.reduce((acc, item: { id: string }) => {
      const formattedValue = item?.id.includes('NEXTDAY')
        ? item?.id.replace('NEXTDAY', 'Next Day ')
        : item?.id.replace('SAMEDAY_', 'Same Day ')

      acc = { ...acc, [item.id]: formattedValue }

      return acc
    }, {} as { [key: string]: string })
  }, [packageServiceTypes])

  const onCarrierChange = useCallback(
    val => {
      carrierInput.onChange(val)
      setIsCarrierManuallySelected(true)
    },
    [carrierInput],
  )

  const onRequiresDeliverySignatureChange = useCallback(
    val => {
      requiresDeliverySignature.onChange(val)
    },
    [requiresDeliverySignature],
  )

  const onMinimumAgeVerificationChange = useCallback(
    val => {
      requiresAgeVerification.onChange(val || null)
    },
    [requiresAgeVerification],
  )

  const canEditRequirements = useMemo(
    () => role === cognitoGroups.admin || role === cognitoGroups.manager,
    [role],
  )

  const toggleRetailerModal = useCallback(() => setIsRetailerModalOpen(!isRetailerModalOpen), [
    isRetailerModalOpen,
  ])

  const clearTrackingId = useCallback(async () => {
    await createAsyncAction(dispatch, actions.clearPackageTrackingId.request({ id: packageId }))

    trackingId.onChange(null)
  }, [dispatch, packageId, trackingId])

  const onNoLabelClick = useCallback(() => {
    const newTrackingId = packageId
      ?.split('-')
      .join('')
      .toUpperCase()
    trackingId.onChange(newTrackingId)
    autoDetectCarrier(newTrackingId)
  }, [autoDetectCarrier, packageId, trackingId])

  const onRetailerModalClose = useCallback(() => setIsRetailerModalOpen(false), [])

  useOutsideClick(retailerModalRef, onRetailerModalClose)

  const addRetailerAvailable = useMemo(
    () =>
      role === cognitoGroups.admin ||
      role === cognitoGroups.manager ||
      role === cognitoGroups.operations,
    [role],
  )

  useEffect(() => {
    if (digitalLabels.includes(labelType.value)) {
      setIsDigitalLabel(true)
    } else {
      setIsDigitalLabel(false)
    }
  }, [labelType.value])

  useEffect(() => {
    if (trackingId && !carrierInput.value) {
      autoDetectCarrier(trackingId.value)
    }
  }, [trackingId, carrierInput.value, autoDetectCarrier])

  useEffect(() => {
    if (carrierInput.value && typeof carrierInput.value === 'object') {
      setIsCarrierManuallySelected(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!retailerInput) {
      setIsAddNewRetailerDisabled(true)
    }
  }, [retailerInput])

  useEffect(() => {
    consolidationId.onChange(startConsolidationId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startConsolidationId])

  useEffect(() => {
    if (partners && partners.length) {
      const destinations = partners.find((p: Partner) => p.code === partner?.value?.code)
        ?.destinations

      setPartnerDestinations(destinations || null)
      setPartnerDestinationValue(
        destinations?.find(d => d?.address?.id === partnerDestinationIdInput.value) || null,
      )
    }
  }, [partnerDestinationIdInput, partners, partner])

  useEffect(() => {
    if (claimReason.value) {
      claimReasons.forEach((reason: { key: ClaimReasons; label: string }) => {
        if (reason.key === claimReason.value) {
          setСlaimReasonValue(reason)
        }
      })
    }
  }, [claimReason.value])

  const partnerAttributes = useMemo<Array<Array<string>>>(() => {
    if (startPackage?.partnerAttributes) {
      let parsedAttributes = JSON.parse(startPackage.partnerAttributes)

      if (typeof parsedAttributes === 'string') {
        parsedAttributes = JSON.parse(parsedAttributes)
      }

      if (Array.isArray(parsedAttributes)) {
        parsedAttributes = parsedAttributes.reduce((acc, obj) => Object.assign(acc, obj), {})
      }

      return Object.entries(parsedAttributes).map(attr => {
        return attr.map(entity => {
          if (typeof entity !== 'string') {
            return ''
          }
          return entity
        })
      })
    }

    return []
  }, [startPackage?.partnerAttributes])

  const onRetailerSelect = useCallback(
    (_, value) => {
      retailer.onChange(value)
    },
    [retailer],
  )

  const onClearConsolidationIdSelect = useCallback(() => {
    dispatch(
      actions.scanBarcode.request({
        consolidationId: null,
      }),
    )
    consolidationId.onChange(null)
    setIsRemovePackageModalOpen(false)
  }, [consolidationId, dispatch])

  const onPartnerChange = useCallback(
    val => {
      partner.onChange(val)
      onClearConsolidationIdSelect()
    },
    [onClearConsolidationIdSelect, partner],
  )

  const handleClaimReasonOpen = useCallback(() => {
    setIsClaimReasonOpen(true)
  }, [])

  const handleClaimReasonClose = useCallback(() => {
    setIsClaimReasonOpen(false)
  }, [])

  const handleRemovePackageFromConsolidation = useCallback(() => {
    setIsRemovePackageModalOpen(true)
  }, [])

  const handlePartnerDestinationOpen = useCallback(() => {
    setIsDestinationOpen(true)
  }, [])

  const handlePartnerDestinationClose = useCallback(() => {
    if (!isAddressModalOpen) {
      setIsDestinationOpen(false)
      setIsAddressModalOpen(false)
    }
  }, [isAddressModalOpen])

  const handleAddressDropdownClose = useCallback(() => {
    setIsDestinationOpen(false)
    setIsAddressModalOpen(false)
  }, [])

  const handleOpenAddressModal = useCallback(() => {
    setIsAddressModalOpen(true)
    setIsDestinationOpen(true)
  }, [])

  const isConsolidationEnabled = useMemo(
    () =>
      partner?.value?.isConsolidationEnabled &&
      (canLinkPackageToConsolidationStatuses.includes(startPackage?.status) ||
        (notRemovePackageFromConsolidationStatuses.includes(startPackage?.status) &&
          startConsolidationId)),
    [partner?.value?.isConsolidationEnabled, startConsolidationId, startPackage?.status],
  )

  const fetchConsolidatedByUser = useCallback(async () => {
    const res: User = await createAsyncAction(
      dispatch,
      actions.getUserById.request({ id: currentPackageData?.consolidatedBy }),
    )

    setConsolidatedByUserEmail(res?.email || null)
  }, [currentPackageData?.consolidatedBy, dispatch])
  const addNewAddress = useCallback(
    (address: Address) => {
      setUserAddresses([...(userAddresses || []), address])
    },
    [userAddresses],
  )

  const createUserAddress = useCallback(
    async (val: PickupDetailsType & { ignoreGeocoding: boolean }): Promise<Address> => {
      return await createAsyncAction(
        dispatch,
        actions.createUserAddress.request({
          userId: currentPackageData?.address?.userId,
          input: {
            name: val.name,
            phone: val.phone,
            street: val.street,
            unit: val.unit,
            city: val.city,
            state: val.state,
            zipCode: val.zipCode,
            instructions: val.instructions,
          },
        }),
      )
    },
    [dispatch, currentPackageData?.address?.userId],
  )

  const fetchUserAddresses = useCallback(async () => {
    if (currentPackageData?.address?.userId) {
      const res: User = await createAsyncAction(
        dispatch,
        actions.getUserById.request({ id: currentPackageData?.address?.userId }),
      )
      setUserAddresses(res?.addresses as Address[])
    }
  }, [dispatch, currentPackageData?.address?.userId])

  useEffect(() => {
    if ((barcodeScan?.consolidationId || barcodeScan?.scannedId) && isConsolidationEnabled) {
      consolidationId.onChange(barcodeScan?.consolidationId || barcodeScan?.scannedId)
    }
  }, [
    barcodeScan?.consolidationId,
    barcodeScan?.scannedId,
    consolidationId,
    isConsolidationEnabled,
  ])

  useEffect(() => {
    if (barcodeScan?.consolidationId || barcodeScan?.scannedId) {
      dispatch(
        actions.scanBarcode.request({
          consolidationId: null,
          scannedId: null,
        }),
      )
    }
  }, [barcodeScan?.scannedId, barcodeScan?.consolidationId, dispatch])

  useEffect(() => {
    fetchUserAddresses()
  }, [fetchUserAddresses])

  useEffect(() => {
    if (startPackage.requiresAgeVerification) {
      requiresAgeVerification.onChange(startPackage.requiresAgeVerification)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startPackage.requiresAgeVerification])

  useEffect(() => {
    requiresDeliverySignature.onChange(startPackage.requiresDeliverySignature)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startPackage.requiresDeliverySignature])

  useEffect(() => {
    if (consolidationId.value) {
      getConsolidations()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (consolidatedBy.value) {
      fetchConsolidatedByUser()
    }
  }, [consolidatedBy.value, fetchConsolidatedByUser])

  return (
    <>
      <div className={classes.detailsRow}>
        <div className={classes.selectWrapper}>
          <p className={classes.inputHead}>Edit Address</p>
          <EditAddressFormInner
            addNewAddress={addNewAddress}
            createAddress={createUserAddress}
            addresses={userAddresses}
            addressIdName="addressId"
          />
        </div>
      </div>

      <div className={classes.detailsRow}>
        <div className={classes.selectWrapper}>
          <p className={classes.inputHead}>Status</p>
          <PackageStatusSelect />
        </div>
      </div>

      {isShowPackageDelayReasonSelect ? (
        <div className={classes.detailsRow}>
          <div className={classes.selectWrapper}>
            <p className={classes.inputHead}>Package Delay Reason:</p>
            <Field name="packageDelayReason">
              {({ input, meta }) => (
                <Select
                  value={input.value}
                  onChange={input.onChange}
                  values={PACKAGE_DELAY_REASON_MAP}
                  className={clsx(classes.selectField, classes.packageDelayReasonSelect)}
                  wrapperClassName={classes.selectWrapper}
                  error={meta.error}
                  withEmpty
                  emptyItemTitle="None"
                  emptyItemValue=""
                />
              )}
            </Field>
          </div>
        </div>
      ) : null}

      {claim.value ? (
        <div className={clsx(classes.selectWrap, classes.claimReasonWrap)}>
          <p className={clsx(classes.inputHead, classes.claimReasonInputHead)}>Claim Reason:</p>
          <Field name="claimReason">
            {({ meta }) => (
              <AutocompleteInput
                options={claimReasons || []}
                onChange={onClaimReasonSelect}
                readOnly={role !== cognitoGroups.admin}
                onOpen={handleClaimReasonOpen}
                onClose={handleClaimReasonClose}
                getOptionLabel={(option: { key: ClaimReasons; label: string }) => `${option.label}`}
                renderOption={(
                  events: HTMLAttributes<HTMLElement>,
                  option: { key: ClaimReasons; label: string },
                ) => (
                  <p
                    {...events}
                    key={option.label}
                    className={`${events.className} ${classes.optionSecondary}`}
                  >
                    {option.label}
                  </p>
                )}
                inputValue={claimReasonValue || ''}
                value={claimReasonValue || null}
                placeholder="Select claim reason..."
                noOptionsText="Select claim reason..."
                meta={meta}
                isOpen={isClaimReasonOpen}
              />
            )}
          </Field>
        </div>
      ) : null}

      <div className={classes.detailsRow}>
        <p className={classes.inputHead}>Label:</p>
        <div className={classes.labelTypeWrap}>
          <Field name="labelType">
            {({ input }) => (
              <RadioGroup {...input} row>
                {Object.keys(LABEL_TYPE_MAP).map(label => (
                  <FormControlLabel
                    key={label}
                    value={label}
                    classes={{
                      root: classes.formControlLabelRoot,
                      label: classes.formControlLabel,
                    }}
                    control={
                      <Radio
                        color="primary"
                        icon={<SelectorIcon />}
                        checkedIcon={<CheckedSelectorIcon />}
                      />
                    }
                    label={LABEL_TYPE_MAP[label as LabelType]}
                  />
                ))}
              </RadioGroup>
            )}
          </Field>
        </div>
      </div>
      {isDigitalLabel ? (
        <div className={classes.detailsRow}>
          <p className={clsx(classes.inputHead, classes.labelTitle)}>
            {isLabelUploaded ? 'Label:' : 'Upload label:'}
          </p>
          <Field name="labelUrl">
            {({ meta }) => (
              <UploadLabelField
                labelType={labelType}
                labelUrl={labelUrl}
                setError={setError}
                setIsLabelUploaded={setIsLabelUploaded}
                error={meta.error}
              />
            )}
          </Field>
        </div>
      ) : null}

      <div className={classes.detailsRow}>
        <div className={classes.selectWrapper}>
          <p className={classes.inputHead}>Handoff Method</p>
          <Field name="method">
            {({ input, meta }) => (
              <Select
                value={input.value}
                onChange={input.onChange}
                values={TRIP_METHOD_MAP}
                className={clsx(classes.selectField, classes.packageDelayReasonSelect)}
                wrapperClassName={classes.selectWrapper}
                error={meta.error}
              />
            )}
          </Field>
        </div>
      </div>

      <div className={classes.checkbox}>
        <div className={classes.swapCheckbox}>
          <p className={classes.inputHeadCheckbox}>Swap</p>
          <Field name="isSwap">
            {({ input }) => <Checkbox checked={input.value} onChange={input.onChange} />}
          </Field>
        </div>
        {isDeliveriesTripType ? (
          <div className={clsx(classes.swapCheckbox, classes.requiresDeliverySignatureCheckbox)}>
            <p className={classes.inputHeadCheckbox}>Signature required</p>
            <Field name="requiresDeliverySignature">
              {({ input }) => (
                <Checkbox
                  disabled={!canEditRequirements}
                  checked={input.value}
                  onChange={onRequiresDeliverySignatureChange}
                />
              )}
            </Field>
          </div>
        ) : null}
        <p className={clsx(classes.inputHeadCheckbox, classes.boxRequiredTitle)}>Claim</p>
        <Field name="claim">
          {({ input }) => (
            <Checkbox
              disabled={role !== cognitoGroups.admin}
              checked={input.value}
              onChange={input.onChange}
            />
          )}
        </Field>
        {startPackage?.verifiedDob ? (
          <>
            <p className={classes.inputHeadCheckbox}>Verified DOB:</p>
            <Field name="verifiedDob">
              {({ input }) => (
                <p
                  className={clsx(classes.requiresAgeVerificationLabel, classes.inputHeadCheckbox)}
                >
                  {input.value}
                </p>
              )}
            </Field>
          </>
        ) : null}
      </div>
      {isDeliveriesTripType ? (
        <div className={classes.detailsRow}>
          <p className={classes.inputHead}>Minimum age</p>
          <Field name="requiresAgeVerification">
            {({ input, meta }) => (
              <Input
                placeholder="Add minimum age"
                classes={{
                  textField: classes.input,
                  input: classes.minimumAge,
                }}
                onChange={onMinimumAgeVerificationChange}
                value={input.value}
                type="number"
                disabled={!canEditRequirements}
                helperText={meta.error}
                error={meta.touched && meta.error}
              />
            )}
          </Field>
        </div>
      ) : null}
      <div className={classes.detailsRow}>
        <p className={classes.inputHead}>Expected Date</p>
        <Field name="expectedDate">
          {({ input }) => (
            <SingleDatePicker
              value={input.value}
              onChange={input.onChange}
              keyboardIcon={CalendarIcon}
              className={classes.disabledDate}
              momentFormat="YYYY-MM-DD"
              disabled
            />
          )}
        </Field>
      </div>
      <div className={classes.detailsRow}>
        <p className={classes.inputHead}>Package Date</p>
        <Field name="packageDate">
          {({ input, meta }) => (
            <SingleDatePicker
              placeholder="Select date"
              value={input.value}
              onChange={input.onChange}
              keyboardIcon={CalendarIcon}
              className={clsx(isPackageDateDisabled ? classes.disabledDate : classes.datePicker)}
              momentFormat="YYYY-MM-DD"
              error={meta.touched && meta.error}
              helperText={meta.error}
              disablePast
              disabled={isPackageDateDisabled}
            />
          )}
        </Field>
      </div>

      <div className={classes.detailsRow}>
        <p className={classes.inputHead}>Description:</p>
        <Field name="description">
          {({ input, meta }) => (
            <Input
              placeholder="Package description, i.e pink shoes"
              classes={{
                textField: classes.descriptionInput,
              }}
              multiline
              onChange={input.onChange}
              value={input.value}
              fullWidth
              helperText={meta.error}
              error={meta.touched && meta.error}
            />
          )}
        </Field>
      </div>
      <div className={classes.packageDetailsBlock}>
        <div className={classes.selectWrap}>
          <p className={classes.inputHead}>Partner:</p>
          <Field name="partner">
            {({ input }) => (
              <WrappedSelect<'code', Partner>
                value={input.value as Partner}
                onChange={onPartnerChange}
                values={partners as Partner[]}
                objectKey="code"
                placeholder="Choose partner..."
                fullWidth
              />
            )}
          </Field>
        </div>
        {isConsolidationEnabled ? (
          <div className={clsx(classes.selectWrap, classes.customCloseWrapper)}>
            <p className={classes.inputHead}>Consolidation ID:</p>
            <Field name="consolidationId">
              {({ input, meta }) => (
                <>
                  <Input
                    placeholder="Enter your consolidation ID"
                    classes={{
                      input: clsx(classes.inputRoot, classes.inputRootConsolidationId),
                    }}
                    onChange={input.onChange}
                    value={input.value}
                    fullWidth
                    helperText={meta.error}
                    error={meta.touched && meta.error}
                  />

                  {input.value &&
                  !notRemovePackageFromConsolidationStatuses.includes(startPackage.status) ? (
                    <div
                      className={classes.closeButton}
                      onClick={handleRemovePackageFromConsolidation}
                    >
                      <CloseIcon
                        className={classes.closeIcon}
                        strokeColor={PURE_RED}
                        width="18"
                        height="18"
                      ></CloseIcon>
                    </div>
                  ) : null}
                </>
              )}
            </Field>
          </div>
        ) : (
          <div className={clsx(classes.selectWrap)} />
        )}
        <div className={classes.selectWrap}>
          <p className={classes.inputHead}>Carrier:</p>
          <Field name="carrier">
            {({ input }) => (
              <WrappedSelect
                value={
                  (consolidationId.value && packageConsolidation?.carrier) ||
                  input.value ||
                  startPackage.carrier
                }
                onChange={onCarrierChange}
                disabled={!!consolidationId.value}
                values={carriers || emptyArray}
                objectKey="id"
                placeholder={consolidationId.value ? '' : 'Choose carrier'}
                className={consolidationId.value ? classes.selectedConsolidationId : ''}
                popupIconStyles={consolidationId.value ? classes.hidePopupIcon : ''}
                fullWidth
              />
            )}
          </Field>
        </div>
        {consolidatedByUserEmail ? (
          <div className={classes.selectWrap}>
            <p className={classes.inputHead}>Consolidated By:</p>
            <Field name="consolidatedByUserEmail">
              {() => (
                <Input
                  classes={{
                    textField: classes.selectedConsolidationId,
                    input: classes.inputConsolidatedBy,
                  }}
                  disabled
                  value={consolidatedByUserEmail}
                  fullWidth
                />
              )}
            </Field>
          </div>
        ) : null}
        <div className={classes.selectWrap}>
          <div className={classes.detailsRow}>
            <div className={classes.inputHeaderWrap}>
              <p className={classes.inputHead}>Retailer:</p>
              {addRetailerAvailable ? (
                <div className={classes.addButton} onClick={toggleRetailerModal}>
                  <PlusIcon /> Add new
                </div>
              ) : null}

              {isRetailerModalOpen && (
                <div ref={retailerModalRef} className={classes.newRetailerModal}>
                  <p className={classes.newRetailerModalTitle}>Add new retailer:</p>
                  <Input
                    placeholder="Retailer name"
                    classes={{
                      textField: classes.input,
                    }}
                    onChange={onRetailerChange}
                    value={retailerInput}
                    fullWidth
                    error={Boolean(retailerError)}
                    helperText={retailerError}
                  />
                  <div
                    className={clsx(classes.modalButtons, { [classes.errorSpace]: retailerError })}
                  >
                    <Button
                      className={clsx(classes.button, classes.cancelButton)}
                      onClick={onRetailerModalClose}
                      label="Cancel"
                      cancelButton
                    />
                    <Button
                      className={clsx(classes.button, classes.retailerButton)}
                      onClick={addRetailer}
                      label="Save"
                      disabled={isAddNewRetailerDisabled}
                    />
                  </div>
                </div>
              )}
            </div>

            <Field name="retailer">
              {({ input, meta }) => (
                <AutocompleteInput
                  options={retailers || []}
                  value={input.value || null}
                  onChange={onRetailerSelect}
                  isOptionEqualToValue={(option: Retailer, value: Retailer) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option: Retailer) => option.name || ''}
                  renderOption={(events: HTMLAttributes<HTMLElement>, option: Retailer) => (
                    <p className={classes.optionPrimary} {...events} key={option.name}>
                      {option.name}
                    </p>
                  )}
                  placeholder="Choose retailer"
                  inputRootStyles={classes.inputRoot}
                  meta={meta}
                />
              )}
            </Field>
          </div>
        </div>
        <div className={classes.selectWrap}>
          <div className={classes.inputHeaderWrap}>
            <p className={classes.inputHead}>Tracking Id:</p>
            {partner.value?.requiresReceiptTracking && !consolidationId.value ? (
              <div className={classes.noLabelButton} onClick={onNoLabelClick}>
                GENERATE TRACKING
              </div>
            ) : null}
            <div className={classes.addButton} onClick={clearTrackingId}>
              <EraserIcon /> Clear
            </div>
          </div>
          <Field name="trackingId">
            {({ input, meta }) => (
              <Input
                placeholder={'Add tracking Id'}
                classes={{
                  textField: classes.input,
                }}
                onChange={onTrackingIdChange}
                value={input.value}
                fullWidth
                helperText={meta.error || meta.submitError}
                error={
                  (!meta.touched && meta.error) || (!meta.dirtySinceLastSubmit && meta.submitError)
                }
              />
            )}
          </Field>
        </div>
        {consolidationId.value ? (
          <div className={classes.selectWrap}>
            <div className={classes.inputHeaderWrap}>
              <p className={classes.inputHead}>Consolidation Tracking Id:</p>
            </div>
            <Input
              placeholder={''}
              classes={{
                textField: classes.input,
                input: classes.selectedConsolidationId,
              }}
              value={packageConsolidation?.trackingId || ''}
              disabled
              fullWidth
            />
          </div>
        ) : null}

        <div className={classes.selectWrap}>
          <p className={classes.inputHead}>Condition:</p>
          <Field name="condition">
            {({ input }) => (
              <Select
                value={input.value}
                onChange={input.onChange}
                values={PACKAGE_CONDITION}
                className={classes.selectField}
                wrapperClassName={classes.selectWrapper}
              />
            )}
          </Field>
        </div>
        <div className={classes.selectWrap}>
          <p className={clsx(classes.inputHead, classes.returnDestinationInputHead)}>
            Return Destination (string):
          </p>
          <Field name="returnDestination">
            {({ input, meta }) => (
              <Input
                placeholder="Add return destination (string)"
                classes={{
                  textField: classes.input,
                }}
                onChange={input.onChange}
                value={input.value}
                fullWidth
                helperText={meta.error}
                error={meta.touched && meta.error}
              />
            )}
          </Field>
        </div>
        <div className={classes.selectWrap}>
          <p className={clsx(classes.inputHead, classes.returnDestinationInputHead)}>
            Return Destination:
          </p>
          <Field name="returnDestinationId">
            {({ meta }) => (
              <AutocompleteInput
                options={partnerDestinations || []}
                onChange={onPartnerDestinationSelect}
                readOnly={notUpdatePackageReturnDestinationIdStatuses?.includes(
                  startPackage?.status,
                )}
                onOpen={handlePartnerDestinationOpen}
                onClose={handlePartnerDestinationClose}
                getOptionLabel={(option: PartnerDestination) =>
                  `${option.address?.street}, ${option.address?.city}, ${option.address?.state}, ${option.address?.zipCode}`
                }
                renderOption={(events: HTMLAttributes<HTMLElement>, option: PartnerDestination) => (
                  <p
                    {...events}
                    key={option.id}
                    className={`${events.className} ${classes.optionSecondary}`}
                  >
                    {option.address?.street}, {option.address?.city}, {option.address?.state},{' '}
                    {option.address?.zipCode}
                  </p>
                )}
                inputValue={partnerDestinationValue || ''}
                value={partnerDestinationValue || null}
                placeholder="Select partner destination..."
                noOptionsText={
                  partner.value
                    ? 'The Selected Partner Has No Return Destinations'
                    : 'Select a Partner to Populate Destinations'
                }
                meta={meta}
                isOpen={isDestinationOpen}
                PaperComponent={options =>
                  isAddressModalOpen ? (
                    <NewAddressDropdown
                      className={options.className}
                      onCloseDropdown={handleAddressDropdownClose}
                      partnerCode={partner.value?.code}
                      setPartnerDestinationValue={setPartnerDestinationValue}
                    />
                  ) : (
                    <PaperComponentCustom
                      className={options.className}
                      children={options.children}
                      showAddButton={Boolean(partner.value)}
                      onButtonClick={handleOpenAddressModal}
                    />
                  )
                }
              />
            )}
          </Field>
        </div>
        <div className={classes.selectWrap}>
          <p className={classes.inputHead}>Dimensions (LxWxH) (in.):</p>
          <div className={classes.dimensionsContainer}>
            <Field name="length">
              {({ input, meta }) => (
                <Input
                  placeholder="Length"
                  classes={{
                    textField: classes.input,
                  }}
                  onChange={input.onChange}
                  value={input.value}
                  type="number"
                  helperText={meta.error}
                  error={meta.touched && meta.error}
                />
              )}
            </Field>
            <Field name="width">
              {({ input, meta }) => (
                <Input
                  placeholder="Width"
                  classes={{
                    textField: classes.input,
                  }}
                  onChange={input.onChange}
                  value={input.value}
                  type="number"
                  helperText={meta.error}
                  error={meta.touched && meta.error}
                />
              )}
            </Field>
            <Field name="height">
              {({ input, meta }) => (
                <Input
                  placeholder="Height"
                  classes={{
                    textField: classes.input,
                  }}
                  onChange={input.onChange}
                  value={input.value}
                  type="number"
                  helperText={meta.error}
                  error={meta.touched && meta.error}
                />
              )}
            </Field>
          </div>
        </div>
        <div className={classes.selectWrap}>
          <p className={classes.inputHead}>Package Weight (oz.):</p>
          <Field name="weight">
            {({ input, meta }) => (
              <Input
                placeholder="Weight"
                classes={{
                  textField: classes.input,
                }}
                onChange={input.onChange}
                value={input.value}
                type="number"
                fullWidth
                helperText={meta.error}
                error={meta.touched && meta.error}
              />
            )}
          </Field>
        </div>
        <div className={classes.selectWrap}>
          <p className={clsx(classes.inputHead, classes.returnDestinationInputHead)}>
            Service Type:
          </p>
          <Field name="serviceType">
            {({ input }) => (
              <Select
                value={input.value}
                onChange={input.onChange}
                values={serviceTypeCondition || {}}
                disabled={role !== cognitoGroups.admin}
                className={classes.selectField}
                wrapperClassName={classes.selectWrapper}
              />
            )}
          </Field>
        </div>
      </div>
      {partnerAttributes.length ? (
        <div className={clsx(classes.detailsBlock, classes.tableWrap)}>
          <p className={clsx(classes.inputHead, classes.boldTitle)}>Partner Attributes</p>
          {partnerAttributes.map(attribute => (
            <div className={classes.detailsDataRow}>
              <div className={classes.detailsType}>{attribute[0]}</div>
              <div className={classes.detailsData}>{attribute[1]}</div>
            </div>
          ))}
        </div>
      ) : null}
      {scans.value.length ? (
        <div className={clsx(classes.detailsBlock, classes.tableWrap, classes.scansContainer)}>
          <p className={clsx(classes.inputHead, classes.boldTitle)}>Barcode Scans</p>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.headerRow}>
                <TableCell className={classes.headerCell}>Date</TableCell>
                <TableCell className={classes.headerCell}>Tracking ID</TableCell>
                <TableCell className={classes.headerCell}>Type</TableCell>
                <TableCell className={classes.headerCell}>Hub</TableCell>
                <TableCell className={classes.headerCell}>Source</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <FieldArray name="scans">
                {({ fields }) =>
                  fields.map(fieldName => <ScansRow fieldName={fieldName} key={fieldName} />)
                }
              </FieldArray>
            </TableBody>
          </Table>
        </div>
      ) : null}
      {locationScans.value.length ? (
        <div className={clsx(classes.detailsBlock, classes.tableWrap)}>
          <p className={clsx(classes.inputHead, classes.boldTitle)}>Location Scans</p>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.headerRow}>
                <TableCell className={classes.headerCell}>Date</TableCell>
                <TableCell className={classes.headerCell}>Location</TableCell>
                <TableCell className={classes.headerCell}>User</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <FieldArray name="locationScans">
                {({ fields }) =>
                  fields.map(fieldName => (
                    <LocationScansRow fieldName={fieldName} key={fieldName} />
                  ))
                }
              </FieldArray>
            </TableBody>
          </Table>
        </div>
      ) : null}
      <div className={clsx(classes.detailsBlock, classes.tableWrap)}>
        <p className={clsx(classes.inputHead, classes.boldTitle)}>Status History</p>
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.headerRow}>
              <TableCell className={classes.headerCell}>Date</TableCell>
              <TableCell className={classes.headerCell}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {packageTrackingList?.map(tracking => (
              <TrackingRow key={tracking.id} tracking={tracking} />
            ))}
          </TableBody>
        </Table>
      </div>
      {startPackage?.source ? (
        <div className={clsx(classes.detailsBlock, classes.tableWrap)}>
          <p className={clsx(classes.inputHead, classes.boldTitle)}>Package Origin</p>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.headerRow}>
                <TableCell className={classes.headerCell}>Date</TableCell>
                <TableCell className={classes.headerCell}>Source</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.headerCell}>
                  {startPackage?.createdAt
                    ? moment(startPackage.createdAt * 1000).format('MM-DD-YYYY HH:mm')
                    : null}
                </TableCell>
                <TableCell className={`${classes.headerCell} ${classes.headerCellSource}`}>
                  {`Created via ${startPackage?.source} ${
                    startPackage?.source === SourceType.WILSON
                      ? startPackage?.createdBy
                        ? `- ${startPackage?.createdBy?.email}`
                        : ''
                      : ''
                  }`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      ) : null}
      <div className={classes.detailsBlock}>
        <p className={classes.inputHead}>Media</p>
        <MediaData media={inputMedia.value} onMediaChange={inputMedia.onChange} isOpen={isOpen} />
      </div>
      {error ? <p className={classes.error}>{error}</p> : null}
      <RemovePackageModal
        isOpen={isRemovePackageModalOpen}
        onClose={handleCloseRemovePackageModal}
        onConfirm={onClearConsolidationIdSelect}
      />
    </>
  )
}

export default memo(EditPackageFormInner)
