import {
  ConsolidationStatus,
  PackageStatus,
} from '@returnmates/client-core/src/graphql/generated/api'
import { TripStatus } from '@returnmates/client-core/src/graphql/generated/api'

import CancelIcon from '../../components/images/icons/cancelIcon'
import CompleteIcon from '../../components/images/icons/completeIcon'
import PendingIcon from '../../components/images/icons/pendingIcon'
import {
  CONSOLIDATIONS,
  DASHBOARD,
  MAIN,
  OPERATIONS,
  PACKAGE_LOG,
  TRIPS_LOG,
} from '../../constants/paths'
import { IconComponent, RoutePricingDriverStatus, StatusIconProps } from './types'

export const tabs = ['pickups', 'deliveries']
export const auditTabs = ['audits', 'savers']
export const consolidationTabs = ['inbound', 'outbound']

export function getStatusIcons(status: StatusIconProps): IconComponent {
  switch (status) {
    case TripStatus.COMPLETE:
    case PackageStatus.DELIVERED:
    case PackageStatus.RECEIVED:
    case PackageStatus.DEPARTED:
    case ConsolidationStatus.SHIPPED:
    case ConsolidationStatus.DEPARTED:
      return CompleteIcon

    case TripStatus.CANCELED:
    case TripStatus.FAILED:
    case PackageStatus.RTS:
    case TripStatus.TASK_NOT_CREATED:
    case PackageStatus.DELAYED:
    case 'Delayed (Mis-sort)' as PackageStatus:
    case ConsolidationStatus.DELAYED_MISSORT:
      return CancelIcon

    default:
      return PendingIcon
  }
}

export const pagesWithServicedPartners = [
  TRIPS_LOG,
  PACKAGE_LOG,
  CONSOLIDATIONS,
  OPERATIONS,
  DASHBOARD,
  MAIN,
]

export const DRIVER_STATUS_MAP = {
  [RoutePricingDriverStatus.COMPLETE]: 'Complete',
  [RoutePricingDriverStatus.ERROR]: 'Error',
  [RoutePricingDriverStatus.IN_PROGRESS]: 'In progress',
  [RoutePricingDriverStatus.QUEUED]: 'Queued',
  [RoutePricingDriverStatus.UNKNOWN]: 'Unknown',
}
