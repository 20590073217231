import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import StaticDatePicker from '@mui/lab/StaticDatePicker'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import clsx from 'clsx'
import moment from 'moment'
import { ElementType, memo, useCallback, useMemo } from 'react'

import CalendarIcon from '../images/icons/calendar'
import useStyles from './styles'

interface Props {
  value: string
  onChange: (date: Date | null, value: string) => void
  placeholder?: string
  inputVariant?: TextFieldProps['variant']
  format?: string
  fullWidth?: boolean
  disabled?: boolean
  disablePast?: boolean
  className?: string
  keyboardIcon?: ElementType
  maxDate?: string | null
  minDate?: string | null
  error?: boolean
  helperText?: string
  momentFormat?: string
  isStatic?: boolean
  unavailableDates?: Array<string>
  disableSundays?: boolean
}

function SingleDatePicker({
  placeholder,
  format,
  value,
  onChange,
  className,
  disabled,
  disablePast,
  maxDate,
  minDate,
  error,
  helperText,
  momentFormat,
  fullWidth,
  isStatic = false,
  unavailableDates,
  disableSundays,
}: Props) {
  const classes = useStyles()
  const validDate = useMemo(() => moment(value, momentFormat || format || 'MM-DD-YYYY').toDate(), [
    momentFormat,
    format,
    value,
  ])
  const isValidDate = useMemo(() => moment(validDate).isValid(), [validDate])

  const validMaxDate = useMemo(
    () => moment(maxDate, momentFormat || format || 'MM-DD-YYYY').toDate(),
    [maxDate, momentFormat, format],
  )

  const validMinDate = useMemo(
    () => moment(minDate, momentFormat || format || 'MM-DD-YYYY').toDate(),
    [minDate, momentFormat, format],
  )

  const isValidMaxDate = useMemo(() => moment(validMaxDate).isValid(), [validMaxDate])
  const isValidMinDate = useMemo(() => moment(validMinDate).isValid(), [validMinDate])

  const shouldDisableDate = useCallback(
    date => {
      const currentDate = moment(date).format('yyyy-MM-DD')

      if (unavailableDates && unavailableDates.length) {
        return unavailableDates?.includes(currentDate)
      }

      if (disableSundays) {
        return date.getDay() === 0
      }

      return false
    },
    [disableSundays, unavailableDates],
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {isStatic ? (
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          views={['month', 'day']}
          inputFormat="MM-dd-yyyy"
          value={value && isValidDate ? validDate : null}
          disablePast={disablePast}
          maxDate={maxDate && isValidMaxDate ? validMaxDate : null}
          minDate={minDate && isValidMinDate ? validMinDate : null}
          onChange={onChange}
          disabled={disabled}
          shouldDisableDate={shouldDisableDate}
          renderInput={params => <TextField {...params} />}
          components={{
            OpenPickerIcon: CalendarIcon,
          }}
        />
      ) : (
        <DesktopDatePicker
          inputFormat="MM-dd-yyyy"
          mask="__-__-____"
          views={['month', 'day']}
          value={value && isValidDate ? validDate : null}
          disablePast={disablePast}
          maxDate={maxDate && isValidMaxDate ? validMaxDate : null}
          minDate={minDate && isValidMinDate ? validMinDate : null}
          onChange={onChange}
          disabled={disabled}
          shouldDisableDate={shouldDisableDate}
          renderInput={params => (
            <TextField
              fullWidth={fullWidth}
              {...params}
              placeholder={placeholder}
              className={clsx(className, classes.input, classes.dateInput)}
              error={Boolean(error && helperText)}
              helperText={
                error && helperText ? <div className={classes.errorHandler}>{helperText}</div> : ''
              }
              inputProps={{
                ...params.inputProps,
                placeholder: 'MM-DD-YYYY',
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
            />
          )}
          components={{
            OpenPickerIcon: CalendarIcon,
          }}
        />
      )}
    </LocalizationProvider>
  )
}

export default memo(SingleDatePicker)
